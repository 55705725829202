import * as React from "react";

const SVGManageDocs = (props) => (
	<svg
		width={110}
		height={108}
		viewBox="0 0 110 108"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M69.4519 19.6078C73.0193 19.6078 76.3024 21.46 78.0861 24.4787L92.3466 48.6136C94.1572 51.678 94.1475 55.4778 92.321 58.569L78.162 82.532C76.3355 85.6233 72.9727 87.5314 69.3513 87.5314L40.8304 87.5314C37.263 87.5314 33.9799 85.6793 32.1962 82.6605L17.9357 58.5257C16.1251 55.4612 16.1348 51.6615 17.9613 48.5703L32.1203 24.6072C33.9468 21.516 37.3096 19.6078 40.931 19.6078L69.4519 19.6078Z"
			fill="#fbba00"
		/>
		<path
			d="M43.5 41.75H68.5V46.75H43.5V41.75ZM64.75 48H44.75V61.75C44.75 62.413 45.0134 63.0489 45.4822 63.5178C45.9511 63.9866 46.587 64.25 47.25 64.25H64.75C65.413 64.25 66.0489 63.9866 66.5178 63.5178C66.9866 63.0489 67.25 62.413 67.25 61.75V48H64.75ZM61 55.5H51V53H61V55.5Z"
			fill="black"
		/>
	</svg>
);

export default SVGManageDocs;
