import * as React from "react";

const SVGIncreasePerformance = (props) => (
	<svg
		width={110}
		height={108}
		viewBox="0 0 110 108"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M69.4519 19.6078C73.0193 19.6078 76.3024 21.46 78.0861 24.4787L92.3466 48.6136C94.1572 51.678 94.1475 55.4778 92.321 58.569L78.162 82.532C76.3355 85.6233 72.9727 87.5314 69.3513 87.5314L40.8304 87.5314C37.263 87.5314 33.9799 85.6793 32.1962 82.6605L17.9357 58.5257C16.1251 55.4612 16.1348 51.6615 17.9613 48.5703L32.1203 24.6072C33.9468 21.516 37.3096 19.6078 40.931 19.6078L69.4519 19.6078Z"
			fill="#fbba00"
		/>
		<g clipPath="url(#clip0_257_215)">
			<path
				d="M68.9815 50.239L66.9327 48.2195L58.6205 56.561C58.5229 56.8341 58.3863 57.0585 58.2107 57.2341C57.8205 57.6244 57.3424 57.7805 56.7766 57.7024C56.2693 57.722 55.84 57.5561 55.4888 57.2049C55.3132 57.0488 55.1863 56.8439 55.1083 56.5902L54.0254 55.5073C53.9863 55.5659 53.4546 56.1561 52.4302 57.278C51.4059 58.4 50.8449 59.0098 50.7473 59.1073C50.3571 59.4976 49.8693 59.6537 49.2839 59.5756C48.7766 59.5951 48.3473 59.4293 47.9961 59.078C47.8205 58.922 47.6937 58.7171 47.6156 58.4634L47.4693 58.3171L43.8985 61.8878C43.879 61.9073 43.8546 61.9268 43.8254 61.9463C43.7961 61.9659 43.7717 61.9854 43.7522 62.0049V65.2244H68.1034C68.6107 65.2244 69.0498 65.4098 69.4205 65.7805C69.7912 66.1512 69.9766 66.5951 69.9766 67.1122C69.9766 67.6293 69.7912 68.0683 69.4205 68.4293C69.0498 68.7902 68.6107 68.9707 68.1034 68.9707H41.879C41.3522 68.9707 40.9083 68.7902 40.5473 68.4293C40.1863 68.0683 40.0059 67.6244 40.0059 67.0976V40.8732C40.0059 40.3659 40.1863 39.9268 40.5473 39.5561C40.9083 39.1854 41.3473 39 41.8644 39C42.3815 39 42.8254 39.1854 43.1961 39.5561C43.5668 39.9268 43.7522 40.3659 43.7522 40.8732V57.1171L45.7424 55.1268C45.8205 54.8927 45.9473 54.6878 46.1229 54.5122C46.4742 54.161 46.9034 53.9951 47.4107 54.0146C47.9766 53.9561 48.4546 54.1122 48.8449 54.4829C49.0205 54.6585 49.1571 54.8927 49.2546 55.1854L49.3424 55.2732L52.2985 52.3171C52.3766 52.0829 52.5034 51.878 52.679 51.7024C53.0302 51.3512 53.4595 51.1854 53.9668 51.2049C54.5522 51.1463 55.0302 51.3024 55.401 51.6732C55.5766 51.8488 55.7132 52.0829 55.8107 52.3756L56.8351 53.4L64.4742 45.761L62.4839 43.7707C62.4644 43.0683 62.679 42.7268 63.1278 42.7463H69.04C69.3132 42.7659 69.5327 42.8585 69.6985 43.0244C69.8644 43.1902 69.9571 43.4098 69.9766 43.6829V49.5951C69.9961 50.0634 69.6644 50.278 68.9815 50.239Z"
				fill="black"
			/>
		</g>
		<defs>
			<clipPath id="clip0_257_215">
				<rect
					width={30}
					height={29.9707}
					fill="white"
					transform="translate(40 39)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default SVGIncreasePerformance;
