import * as React from "react";

const SVGSetTasks = (props) => (
	<svg
		width={110}
		height={108}
		viewBox="0 0 110 108"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M69.4519 19.6078C73.0193 19.6078 76.3024 21.46 78.0861 24.4787L92.3466 48.6136C94.1572 51.678 94.1475 55.4778 92.321 58.569L78.162 82.532C76.3355 85.6233 72.9727 87.5314 69.3513 87.5314L40.8304 87.5314C37.263 87.5314 33.9799 85.6793 32.1962 82.6605L17.9357 58.5257C16.1251 55.4612 16.1348 51.6615 17.9613 48.5703L32.1203 24.6072C33.9468 21.516 37.3096 19.6078 40.931 19.6078L69.4519 19.6078Z"
			fill="#fbba00"
		/>
		<path
			d="M70 60.382L68 59.382V57C67.9976 55.5834 67.4944 54.2133 66.5794 53.1318C65.6645 52.0503 64.3966 51.3271 63 51.09V49H61V51.09C59.6034 51.3271 58.3355 52.0503 57.4206 53.1318C56.5056 54.2133 56.0024 55.5834 56 57V59.382L54 60.382V65H60V67H64V65H70V60.382ZM68 63H56V61.618L58 60.618V57C58 55.9391 58.4214 54.9217 59.1716 54.1716C59.9217 53.4214 60.9391 53 62 53C63.0609 53 64.0783 53.4214 64.8284 54.1716C65.5786 54.9217 66 55.9391 66 57V60.618L68 61.618V63Z"
			fill="black"
		/>
		<path
			d="M68 43C68 42.4696 67.7893 41.9609 67.4142 41.5858C67.0391 41.2107 66.5304 41 66 41H62V39H60V41H52V39H50V41H46C45.4696 41 44.9609 41.2107 44.5858 41.5858C44.2107 41.9609 44 42.4696 44 43V63C44 63.5304 44.2107 64.0391 44.5858 64.4142C44.9609 64.7893 45.4696 65 46 65H50V63H46V43H50V45H52V43H60V45H62V43H66V49H68V43Z"
			fill="black"
		/>
	</svg>
);

export default SVGSetTasks;
