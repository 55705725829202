import * as React from "react";

const SVGAr = (props) => (
	<svg
		width={110}
		height={108}
		viewBox="0 0 110 108"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M69.4519 19.6078C73.0193 19.6078 76.3024 21.46 78.0861 24.4787L92.3466 48.6136C94.1572 51.678 94.1475 55.4778 92.321 58.569L78.162 82.532C76.3355 85.6233 72.9727 87.5314 69.3513 87.5314L40.8304 87.5314C37.263 87.5314 33.9799 85.6793 32.1962 82.6605L17.9357 58.5257C16.1251 55.4612 16.1348 51.6615 17.9613 48.5703L32.1203 24.6072C33.9468 21.516 37.3096 19.6078 40.931 19.6078L69.4519 19.6078Z"
			fill="#fbba00"
		/>
		<g clipPath="url(#clip0_257_237)">
			<path
				d="M67.2381 67.9707H44.76C43.7259 67.9707 42.8429 67.6049 42.1112 66.8732C41.3795 66.1415 41.0137 65.2585 41.0137 64.2244V41.7463C41.0137 40.7122 41.3795 39.8293 42.1112 39.0976C42.8429 38.3659 43.7259 38 44.76 38H67.2381C68.2722 38 69.1551 38.3659 69.8868 39.0976C70.6185 39.8293 70.9844 40.7122 70.9844 41.7463V64.2244C70.9844 65.2585 70.6185 66.1415 69.8868 66.8732C69.1551 67.6049 68.2722 67.9707 67.2381 67.9707ZM50.3795 59.5415C50.3795 59.2878 50.2868 59.0683 50.1015 58.8829C49.9161 58.6976 49.6966 58.6049 49.4429 58.6049H47.5698C47.3161 58.6049 47.0966 58.6976 46.9112 58.8829C46.7259 59.0683 46.6332 59.2878 46.6332 59.5415V63.2878C46.6332 63.5415 46.7259 63.761 46.9112 63.9463C47.0966 64.1317 47.3161 64.2244 47.5698 64.2244H49.4429C49.6966 64.2244 49.9161 64.1317 50.1015 63.9463C50.2868 63.761 50.3795 63.5415 50.3795 63.2878V59.5415ZM57.8722 52.0488C57.8722 51.7951 57.7795 51.5756 57.5942 51.3902C57.4088 51.2049 57.1893 51.1122 56.9356 51.1122H55.0625C54.8088 51.1122 54.5893 51.2049 54.4039 51.3902C54.2185 51.5756 54.1259 51.7951 54.1259 52.0488V63.2878C54.1259 63.5415 54.2185 63.761 54.4039 63.9463C54.5893 64.1317 54.8088 64.2244 55.0625 64.2244H56.9356C57.1893 64.2244 57.4088 64.1317 57.5942 63.9463C57.7795 63.761 57.8722 63.5415 57.8722 63.2878V52.0488ZM65.3649 42.6829C65.3649 42.4293 65.2722 42.2098 65.0868 42.0244C64.9015 41.839 64.682 41.7463 64.4283 41.7463H62.5551C62.3015 41.7463 62.082 41.839 61.8966 42.0244C61.7112 42.2098 61.6185 42.4293 61.6185 42.6829V63.2878C61.6185 63.5415 61.7112 63.761 61.8966 63.9463C62.082 64.1317 62.3015 64.2244 62.5551 64.2244H64.4283C64.682 64.2244 64.9015 64.1317 65.0868 63.9463C65.2722 63.761 65.3649 63.5415 65.3649 63.2878V42.6829Z"
				fill="black"
			/>
		</g>
		<defs>
			<clipPath id="clip0_257_237">
				<rect
					width={30}
					height={29.9707}
					fill="white"
					transform="translate(41 38)"
				/>
			</clipPath>
		</defs>
	</svg>
);

export default SVGAr;
