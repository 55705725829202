import * as React from "react";

const SVGSas = (props) => (
	<svg
		width={110}
		height={108}
		viewBox="0 0 110 108"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M69.4519 19.6078C73.0193 19.6078 76.3024 21.46 78.0861 24.4787L92.3466 48.6136C94.1572 51.678 94.1475 55.4778 92.321 58.569L78.162 82.532C76.3355 85.6233 72.9727 87.5314 69.3513 87.5314L40.8304 87.5314C37.263 87.5314 33.9799 85.6793 32.1962 82.6605L17.9357 58.5257C16.1251 55.4612 16.1348 51.6615 17.9613 48.5703L32.1203 24.6072C33.9468 21.516 37.3096 19.6078 40.931 19.6078L69.4519 19.6078Z"
			fill="#fbba00"
		/>
		<path
			d="M66 53C66 51.6213 64.8787 50.5 63.5 50.5H62.25V46.75C62.25 43.3038 59.4462 40.5 56 40.5C52.5538 40.5 49.75 43.3038 49.75 46.75V50.5H48.5C47.1212 50.5 46 51.6213 46 53V63C46 64.3787 47.1212 65.5 48.5 65.5H63.5C64.8787 65.5 66 64.3787 66 63V53ZM52.25 46.75C52.25 44.6825 53.9325 43 56 43C58.0675 43 59.75 44.6825 59.75 46.75V50.5H52.25V46.75Z"
			fill="black"
		/>
	</svg>
);

export default SVGSas;
