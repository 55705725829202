import * as React from "react";

const SVGBreatheBg = (props) => (
	<svg
		width={1440}
		height={537}
		viewBox="0 0 1440 537"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M-51 218.683C183.519 389.468 1044.58 -39.0455 1459.35 2.90885L1469 386.274C1469 386.274 504.875 362.711 386.616 445.296C268.357 527.88 -51 445.296 -51 445.296V218.683Z"
			fill="url(#paint0_linear_125_1917)"
		/>
		<path
			d="M1480 273.683C1245.48 444.468 384.416 15.9545 -30.3478 57.9089L-40 441.274C-40 441.274 924.125 417.711 1042.38 500.296C1160.64 582.88 1480 500.296 1480 500.296V273.683Z"
			fill="url(#paint1_linear_125_1917)"
		/>
		<defs>
			<linearGradient
				id="paint0_linear_125_1917"
				x1={92.4049}
				y1={229.191}
				x2={1457.99}
				y2={323.295}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FFD337" />
				<stop offset={1} stopColor="#FFD337" stopOpacity={0.52} />
			</linearGradient>
			<linearGradient
				id="paint1_linear_125_1917"
				x1={1336.6}
				y1={284.191}
				x2={-28.9853}
				y2={378.295}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FFD337" />
				<stop offset={1} stopColor="#FFD337" stopOpacity={0.52} />
			</linearGradient>
		</defs>
	</svg>
);

export default SVGBreatheBg;
