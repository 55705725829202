import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import ServiceCard from "../components/service-card";
import BeeBanner from "../components/bee-banner";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import SVGFolder from "../components/SVG/folder-svg";
import SVGBreatheBg from "../components/SVG/breathe-bg";
import SVGHolidayR from "../components/SVG/holiday-request";
import SVGManageSickness from "../components/SVG/manage-sickness";
import SVGAr from "../components/SVG/automate-reporting";
import SVGManageDocs from "../components/SVG/manage-documents";
import SVGAe from "../components/SVG/approve-expenses";
import SVGSetTasks from "../components/SVG/set-tasks";
import SVGIncreasePerformance from "../components/SVG/increase-performance";
import SVGSas from "../components/SVG/safe-and-secure";
import SVGPolygon from "../components/SVG/polygon";
import { Badge } from "react-bootstrap";

const HrSoftware = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Home-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			breatheDesktopImg: wpMediaItem(title: { eq: "Breathe-Image-Desktop" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			breatheImage: wpMediaItem(title: { eq: "Breathe-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			breatheGoldImg: wpMediaItem(title: { eq: "Breathe-Image-Gold" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			badgeImg: wpMediaItem(title: { eq: "Advocate of the year" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const breatheDesktopImg =
		data.breatheDesktopImg?.localFile.childImageSharp.gatsbyImageData;
	const breatheImage =
		data.breatheImage?.localFile.childImageSharp.gatsbyImageData;
	const breatheGoldImg =
		data.breatheGoldImg?.localFile.childImageSharp.gatsbyImageData;
	const badgeImg = data.badgeImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "HR Software",
				item: {
					url: `${siteUrl}/hr-software`,
					id: `${siteUrl}/hr-software`,
				},
			},
		],
	};

	if (typeof window !== "undefined") {
		return (
			<Layout>
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>

				<GatsbySeo
					title="HR Software | Hive HR Solutions"
					description="Let Hive HR Solutions take the sting out of your HR Admin & Paperwork in Partnership with Breathe. / placeholder"
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/hr-software`,
						title: "HR Software | Hive HR Solutions",
						description:
							"Let Hive HR Solutions take the sting out of your HR Admin & Paperwork in Partnership with Breathe. / placeholder",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>

				<section
					id="introduction"
					className="pb-5 pt-lg-6 pt-4 pb-md-8 pb-lg-9 position-relative"
				>
					<SVGBreatheBg
						style={{ zIndex: -2, top: "14rem" }}
						className="position-absolute  d-none topXl    h-auto w-100 d-md-block "
					/>
					<Container>
						<Row className="pb-lg-7 pb-4 pt-3 ">
							<Col lg={8} xl={7}>
								<div className="position-relative d-inline-block">
									<SVGPolygon
										className="position-absolute d-none d-lg-block"
										style={{ zIndex: -1, right: "-1.2rem", top: "-1.5rem" }}
									/>
									<h1 className="pt-1 display-5 d-inline-block mb-3">
										HR software: Breathe
									</h1>
								</div>

								<hr className="hr-primary  mb-5" />
							</Col>
						</Row>
						<Row className="align-items-center">
							<Col className="text-center" xs={12} md={6}>
								<GatsbyImage
									className=" mx-auto"
									image={breatheDesktopImg}
									alt={data.breatheDesktopImg?.altText}
								></GatsbyImage>
							</Col>

							<Col
								className="pt-4 text-center d-none d-md-block pt-lg-0"
								xs={12}
								md={6}
							>
								<GatsbyImage
									className="mx-auto "
									image={breatheImage}
									alt={data.breatheImage?.altText}
								></GatsbyImage>
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<Container>
						<Row>
							<Col>
								<h2 className="text-center text-lg-start">Features</h2>
								<hr className="hr-primary mx-auto mx-lg-0 w-40 w-md-30 mb-6" />
							</Col>
						</Row>
						<Row>
							{" "}
							<ServiceCard
								icon={<SVGFolder width={100} />}
								header="EMPLOYEE DATA"
								text="Centralise all your employee information in one location, so its organised and easy to access on the go."
							/>
							<ServiceCard
								icon={<SVGHolidayR width={100} />}
								header="MANAGE HOLIDAY REQUESTS"
								text="Booking holiday with employee self-service and a central calendar makes managing leave requests easy."
							/>
							<ServiceCard
								icon={<SVGManageSickness width={100} />}
								header="MANAGE SICKNESS & ABSENCES"
								text="From monitoring sick leave to generating reports, managing absence is easy with our central calendar.

"
							/>
							<ServiceCard
								icon={<SVGAr width={100} />}
								header="AUTOMATE REPORTING"
								text="Easily generate reports from our report library to spot company trends."
							/>
							<ServiceCard
								icon={<SVGManageDocs width={100} />}
								header="MANAGE DOCUMENTS"
								text="Have all your company and employee documents in one central location."
							/>
							<ServiceCard
								icon={<SVGAe width={100} />}
								header="APPROVE EXPENSES"
								text="Instantly manage, track and approve all the expenses employees claim."
							/>
							<ServiceCard
								icon={<SVGSetTasks width={100} />}
								header="SET TASKS & GET REMINDERS"
								text="With our dashboard, you can see your to-do list, set tasks, and get reminders."
							/>
							<ServiceCard
								icon={<SVGIncreasePerformance width={100} />}
								header="INCREASE PERFORMANCE"
								text="Manage appraisals, give praise, set one-to-one meetings and objectives."
							/>
							<ServiceCard
								icon={<SVGSas width={100} />}
								header="SAFE & SECURE"
								text="Know that all your data is stored securely and you have the ability to control who sees what."
							/>
						</Row>
					</Container>
				</section>
				<section className=" pt-3 pb-6">
					<Container>
						{" "}
						<Row className="justify-content-center align-items-center g-5">
							<Col md={4} lg={3} className="text-center">
								<GatsbyImage
									className=""
									image={breatheGoldImg}
									alt={data.breatheGoldImg?.altText}
								></GatsbyImage>
							</Col>
							<Col md={4} lg={3} className="text-center">
								<GatsbyImage
									className=""
									image={badgeImg}
									alt="Advocate of the year"
								></GatsbyImage>
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					{" "}
					<BeeBanner
						headline="Ready to speak to us?"
						btnTxt="Call us"
						btnLink="tel:+441403627630"
					/>
				</section>
			</Layout>
		);
	}
};

export default HrSoftware;
