import * as React from "react";

const SVGManageSickness = (props) => (
	<svg
		width={110}
		height={108}
		viewBox="0 0 110 108"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M69.4519 19.6078C73.0193 19.6078 76.3024 21.46 78.0861 24.4787L92.3466 48.6136C94.1572 51.678 94.1475 55.4778 92.321 58.569L78.162 82.532C76.3355 85.6233 72.9727 87.5314 69.3513 87.5314L40.8304 87.5314C37.263 87.5314 33.9799 85.6793 32.1962 82.6605L17.9357 58.5257C16.1251 55.4612 16.1348 51.6615 17.9613 48.5703L32.1203 24.6072C33.9468 21.516 37.3096 19.6078 40.931 19.6078L69.4519 19.6078Z"
			fill="#fbba00"
		/>
		<path
			d="M47.25 65.5H64.75C66.1287 65.5 67.25 64.3787 67.25 63V45.5C67.25 44.1212 66.1287 43 64.75 43H62.25V40.5H59.75V43H52.25V40.5H49.75V43H47.25C45.8712 43 44.75 44.1212 44.75 45.5V63C44.75 64.3787 45.8712 65.5 47.25 65.5ZM47.25 46.75H64.75V49.25H47.25V46.75Z"
			fill="black"
		/>
	</svg>
);

export default SVGManageSickness;
