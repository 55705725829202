import * as React from "react";

const SVGHolidayR = (props) => (
	<svg
		width={110}
		height={108}
		viewBox="0 0 110 108"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M69.4519 19.6078C73.0193 19.6078 76.3024 21.46 78.0861 24.4787L92.3466 48.6136C94.1572 51.678 94.1475 55.4778 92.321 58.569L78.162 82.532C76.3355 85.6233 72.9727 87.5314 69.3513 87.5314L40.8304 87.5314C37.263 87.5314 33.9799 85.6793 32.1962 82.6605L17.9357 58.5257C16.1251 55.4612 16.1348 51.6615 17.9613 48.5703L32.1203 24.6072C33.9468 21.516 37.3096 19.6078 40.931 19.6078L69.4519 19.6078Z"
			fill="#fbba00"
		/>
		<path
			d="M48.025 68.1H51.025L57.757 56.0985H65.5C65.5 56.0985 70 56.0985 70 54C70 51.9 65.5 51.9 65.5 51.9H57.757L51.025 39.9H48.025L51.757 51.9H46.3765L43 48.8985H40L42.4015 53.9985L40 59.1H43L46.3765 56.0985H51.757L48.025 68.1Z"
			fill="black"
		/>
	</svg>
);

export default SVGHolidayR;
